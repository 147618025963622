var CookieBar = {
    cookiePrefix: 'website_accepted_',
    // website_accepted_close = mention is gone
    // website_accepted_accepted = cookie is accepted

    init: function() {
        var checkCloseCookieExists = CookieBar.checkCloseCookie();

        if (!checkCloseCookieExists && window.location.pathname != $('#cookieMessage button.goconfigure').data("url")) {
            $('#cookieMessage').addClass('open');
        }

        $('#cookieMessage button.goconfigure').on('click', function (e) {
            e.preventDefault();
            if ($(this).data("url")) {
                window.location = $(this).data("url");
            }
        });

        $('#cookieConfigure button.configure').on('click', function (e) {
            e.preventDefault();
            CookieBar.cleanUp();
            CookieBar.setCloseCookie();
            CookieBar.hideCookiebar();
            CookieBar.configureCookie();
            location = location;
        });

        if ($('#cookieConfigure').length > 0)
        {
            var cookieconf = CookieBar.checkAcceptedCookie();
            if (cookieconf != null) {
                console.log(cookieconf);
                document.getElementById('Functional').checked = cookieconf.functional;
                document.getElementById('Analytics').checked = cookieconf.analytics;
                document.getElementById('Tracking').checked = cookieconf.tracking;
            }
        }

        $(document).on('click', 'button.agreeCookies', function(e) {
        	e.preventDefault();
        	CookieBar.cleanUp();
        	CookieBar.setCloseCookie();
        	CookieBar.cleanUpAccepted();
        	CookieBar.setAcceptedCookie();
        	CookieBar.hideCookiebar();

        	location = location;
        });

    },

    showCookiebar: function() {
    	$('#cookieMessage').slideDown();
    },

    hideCookiebar: function() {
    	$('#cookieMessage').stop(true, true).delay(160).slideUp();
    },

	//CLOSE:
	/* clean up old cookies */
    cleanUp: function () {
    	Cookie.deleteCookie(CookieBar.cookiePrefix + 'close');
    },

	/* collect accepted cookies */
    setCloseCookie: function () {
    	Cookie.setCookie(CookieBar.cookiePrefix + 'close', true, 365);
    },

    checkCloseCookie: function () {
    	return Cookie.getCookie(CookieBar.cookiePrefix + 'close') ? true : false;
    },

	//ACCEPTED:
	/* clean up old cookies */
    cleanUpAccepted: function () {
    	Cookie.deleteCookie(CookieBar.cookiePrefix + 'accepted');
    },

	/* collect accepted cookies */
    setAcceptedCookie: function () {
        var accepted = {}
        accepted["functional"] = true;
        accepted["analytics"] = true;
        accepted["tracking"] = true;


    	Cookie.setCookie(CookieBar.cookiePrefix + 'accepted', accepted, 365);
    },

    configureCookie: function () {
        var accepted = {}
        accepted["functional"] = document.getElementById("Functional").checked;
        accepted["analytics"] = document.getElementById("Analytics").checked;
        accepted["tracking"] = document.getElementById("Tracking").checked;
        Cookie.setCookie(CookieBar.cookiePrefix + 'accepted', accepted, 365);

        console.log(CookieBar.checkAcceptedCookie());
    },

    checkAcceptedCookie: function () {
        return JSON.parse(Cookie.getCookie(CookieBar.cookiePrefix + 'accepted'));
    }
}

var Cookie = {
    setCookie: function(name,value,days) {
        if (days) {
            var date = new Date();
            date.setTime(date.getTime()+(days*24*60*60*1000));
            var expires = "; expires="+date.toGMTString();
        }
        else var expires = "";
        document.cookie = name+"="+JSON.stringify(value)+expires+"; path=/";
    },

    getCookie: function(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },

    deleteCookie: function(name) {
        Cookie.setCookie(name,"",-1);
    },

    cookiesEnabled: function() {
        var cookieEnabled = (navigator.cookieEnabled) ? true : false;

        if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled)
        {
            document.cookie="testcookie";
            cookieEnabled = (document.cookie.indexOf("testcookie") != -1) ? true : false;
        }
        return (cookieEnabled);
    }

}
$(CookieBar.init);