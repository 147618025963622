$(function () {
    $('html.no-js').removeClass('no-js');

    window.menu = {
        init: function () {
            menu.sub();
    
            $('header div.openMenu').on('click', function () {
                $('body')[($('body').hasClass('showMenu')) ? 'removeClass' : 'addClass']('showMenu');
            });
    
            $('header nav.main a').on('click', function () {
                if ($(window).width() <= '1000') {
                    if ($(this).parent('li').hasClass('active')) {
                        menu.closeSlideOuts();
                    } else {
                        $(this).parent('li').addClass('active').siblings().removeClass('active');
                        $('.slideOutMenu#' + $(this).data('slide-id')).addClass('open').siblings().removeClass('open');
                    }
    
                    return false;
                } else {
                    $(this).parent('li').addClass('active').siblings().removeClass('active');
                    $('.slideOutMenu#' + $(this).data('slide-id')).addClass('open').siblings().removeClass('open');
    
                    return false;
                }
            });
    
            $('.slideOutMenu').mouseleave(function () {
                if ($(window).width() > '1000') {
                    menu.closeSlideOuts();
                }
            });
    
            $(document).on('click', function (e) {
                if ($(e.target).parents('.slideOutMenu').length <= 0) {
                    menu.closeSlideOuts();
                }
            });
    
            $('span.mobileTitle').on('click', function () {
                menu.closeSlideOuts();
            });
            
            $(window).on('scroll', function () {
                if ($('.slideOutMenu').hasClass('open')) {
                    if ($(this).scrollTop() >= 80) {
                        menu.closeSlideOuts();
                    }
                }
            });
        },
        closeSlideOuts: function () {
            $('.slideOutMenu').removeClass('open');
            $('nav.main ul li').removeClass('active');
        },
        sub: function () {
            $('div.submenu > span.openSub').on('click', function () {
                $('div.submenu')[($('div.submenu').hasClass('showSub')) ? 'removeClass' : 'addClass']('showSub');
            });
        }
    }
    
    window.pagetools = {
        init: function () {
            $('div.pageTools div.toTop').on('click', function () {
                $('html, body').animate({ scrollTop: 0 }, 700);
            });
        }
    }
    
    
    
    window.serviceWidget = {
        init: function () {
    
            $('div.serviceWidget > div.serviceWidget').unwrap();
    
            $('div.serviceWidget form button').remove();
    
            selectBox.init($('div.serviceWidget select'));
    
            $('div.serviceWidget button.showResults').on('click', function () {
                $('div.serviceWidget div.resultsHolder').slideToggle();
            });
    
            $('div.serviceWidget select').on('change', function () {
                serviceWidget.update();
            });
        },
        update: function () {
            var refUrl = '?';
    
            function splitQueryStrings(refUrl) {
                var hasEmpty = false;
                var queryString = refUrl.replace(/\=/g, "#").replace(/\&/g, "#");
                var queryStringSplit = queryString.split("#");
                $.each(queryStringSplit, function (index, value) {
                    if (value.length == 0) {
                        hasEmpty = true;
                    }
                });
    
                return hasEmpty;
            }
            
            $('div.serviceWidget select').each(function () {
                if (splitQueryStrings(refUrl) != true) {
                    refUrl += (refUrl === '?') ? '' : '&';
                    refUrl += this.name + '=' + this.value.replace(/ /g, "%20").replace(/&/g, "%26");
                }
            });
    
            $('div.serviceWidget').addClass('load').load(refUrl + ' .serviceWidget', function (response, status) {
                success: serviceWidget.init();
            });
        }
    }
    
    window.selectBox = {
        init: function (selector) {
            if (selector == null) {
                selector = $('select');
            }
    
            selector.each(function () {
                var $this = $(this),
                    enabled = true,
                    numberOfOptions = $(this).children('option').length;
    
                $this.parent('label').addClass('select');
                $this.addClass('hide');
                $this.after('<div class="styledSelect"></div>');
    
                var $styledSelect = $this.next('div.styledSelect');
                $styledSelect.text(($this.children('option:selected').length > 0) ? $this.children('option:selected').text() : $this.children('option').eq(0).text());
    
                if ($this.is(':disabled'))
                    enabled = false;
    
                if (enabled) {
                    var $list = $('<ul />', {
                        'class': 'options'
                    }).insertAfter($styledSelect);
    
                    for (var i = 0; i < numberOfOptions; i++) {
                        $('<li />', {
                            text: $this.children('option').eq(i).text(),
                            rel: $this.children('option').eq(i).val(),
                            'class': ($this.children('option').eq(i).is(':selected')) ? 'selected' : ''
                        }).appendTo($list);
                    }
    
                    var $listItems = $list.children('li');
    
                    $styledSelect.click(function (e) {
                        e.stopPropagation();
                        $('div.styledSelect.active').each(function () {
                            $(this).removeClass('active').next('ul.options').hide();
                        });
                        $(this).toggleClass('active').next('ul.options').toggle();
                    });
    
                    $listItems.click(function (e) {
                        e.stopPropagation();
                        $styledSelect.text($(this).text()).removeClass('active');
                        $this.val($(this).attr('rel')).change();
                        $(this).addClass('selected').siblings().removeClass('selected');
                        $list.hide();
                    });
    
                    $(document).click(function (e) {
                        if ($(e.target).parent('label').length <= 0) {
                            $styledSelect.removeClass('active');
                            $list.hide();
                        }
                    });
                    if ($styledSelect.text() == '') {
                        $styledSelect.text('Selecteer');
                    }
                }
                else {
                    $styledSelect.addClass('disabled');
                }
            });
        }
    }
    
    window.backToTop = {
        init: function () {
            window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function (f) { setTimeout(f, 1000 / 60) }
    
            function checkIfShow() {
                var scrolltop = window.pageYOffset;
                var footerOffset = $('footer').offset();
    
                if ($('section.breadcrumb').length > 0)
                    $('header')[(scrolltop < $('header').height()) ? 'removeClass' : 'addClass']('fixedBreadcrumb');
    
                if ($('div.backToTop').length > 0)
                    $('div.backToTop')[(scrolltop < 80) ? 'removeClass' : 'addClass']('show')[((window.pageYOffset + $(window).height()) >= footerOffset.top) ? 'addClass' : 'removeClass']('stopped');
            }
    
            window.addEventListener('scroll', function () {
                requestAnimationFrame(checkIfShow);
            }, false)
    
            checkIfShow();
    
            $('div.backToTop').on('click', function () {
                $('html,body').animate({
                    scrollTop: 0
                }, 1000, 'swing');
            });
        }
    }
    
    window.topTasks = {
        
        init: function () {
            /*
            var controlHolder = $('section.breadcrumb div.controls');
    
            $('section.breadcrumb div.controls > span.open').on('click', function () {
                $('section.breadcrumb div.controls, div.topTasks.slideOut').addClass('opened');
            });
    
            $('section.breadcrumb div.controls > span.close').on('click', function () {
                topTasks.close();
            });
            
            $('div.topTasks.slideOut').mouseleave(function () {
                topTasks.close();
            });
            */
        },
        close: function () {
            $('section.breadcrumb div.controls, div.topTasks.slideOut').removeClass('opened');
        }
    }
    
    window.wizard = {
        init: function () {
            window.onpopstate = function () {
                wizard.update(location.href, true);
            }
    
            $('div.wizardHolder > div.wizardHolder').unwrap();
    
            var stps = $('header.wizard > div.container ul.steps.nw');
            if (stps.length > 0) {
                $('header.wizard > div.container ul.steps:not(.nw)').remove().siblings('.nw').removeClass('nw');
                stps.removeClass('nw');
            }
    
            $('div.wizardHolder div.footer button').remove();
    
            $('div.wizardHolder input[type=radio]').unbind('change');
            $('div.wizardHolder input[type=radio]').bind('change', function () {
                wizard.update();
            });
    
            $('header.wizard > div.container ul.steps li a').unbind('click');
            $('header.wizard > div.container ul.steps li a').bind('click', function () {
                wizard.update($(this).attr('href'));
                return false;
            });
        },
        update: function (refUrl, initBool) {
            if (refUrl == null) {
                refUrl = $('div.wizardHolder > form').attr('action') + '?';
    
                $('div.wizardHolder input[type=radio]:checked').each(function () {
                    refUrl += this.name + '=' + encodeURIComponent(this.value);
                });
            }
    
            $('div.wizardHolder').load(refUrl + ' div.wizardHolder', function (response, status) {
                $(response).find('ul.steps').appendTo('header.wizard > div.container').addClass('nw');
                success: {
                    wizard.init();
                    slimmageTools.reload();
                    if (initBool != true) {
                        window.history.pushState({}, '', refUrl);
                    }
                    document.getElementsByTagName('title')[0].innerHTML = $(response).filter('title').text();
    
                    ga('set', { page: refUrl, title: $(response).filter('title').text() });
                    ga('send', 'pageview');
                }
            });
        }
    }
    
    window.slimmageTools = {
        reload: function () {
            var s = window['slimmage'] || {};
            s.cr(500);
        }
    }
    
    window.carousel = {
        init: function () {
            $('.carousel').owlCarousel({
                nav: true,
                navText: ['', ''],
                loop: true,
                slideSpeed: 300,
                paginationSpeed: 400,
                items: 1
            });
        }
    }
    
    window.showmore = {
        init: function () {
            if ($('ul.people').length > 0) {
                $('ul.people li:gt(7)').addClass('hidden');
            }
    
            $('div.showMore').on('click', function () {
                if ($(this).hasClass('showLess')) {
                    $(this).removeClass('showLess');
                    $('ul.people li:gt(7)').addClass('hidden');
                } else {
                    $(this).addClass('showLess');
                    $('ul.people li:gt(7)').removeClass('hidden');
                }
                return false;
            });
        }
    }
    
    window.socialLine = {
        init: function () {
            $('.socialLine').owlCarousel({
                slideSpeed: 300,
                paginationSpeed: 400,
                loop: false,
                margin: 20,
                nav: true,
                navText: ['', ''],
                navRewind: false,
                items: 3,
                responsive:{
                    0:{
                        items: 1
                    },
                    600:{
                        items: 2
                    },
                    930: {
                        items: 3
                    }
                }
            });
        }
    }
    
    window.ambassadors = {
        init: function () {
            if ($('.ambassadors ul li').length) {
                $('.ambassadors ul').owlCarousel({
                    items: 3,
    
                    responsive: {
                        0: {
                            items: 1
                        },
                        800: {
                            items: 2,
                        },
                        1200: {
                            items: 3
                        }
                    }
                });
            }
        }
    }
    
    window.expertises = {
        init: function () {
            if ($("section.expertises").length) {
                $("section.expertises a.expertiseBlock").on('mouseenter', function () {
                    $("section.expertises a.expertiseBlock").removeClass('lastActive');
                    $(this).addClass('lastActive');
                });
            }
        }
    }
    
    window.locationFilter = {
        init: function () {
    
            $(window).on('load', function () {
                var currentSelection = $('select#area').val();
                if (currentSelection != "") {
                    locationFilter.update(currentSelection);
                }
            });
    
            $('div.locationFilter form button').remove();
    
            selectBox.init($('select#area'));
    
            $('div.locationFilter select').on('change', function () {
                locationFilter.update(false);
            });
        },
        update: function (currentSelection) {
            selectBox = $('div.locationFilter select');
    
            if (currentSelection != false) {
                var refUrl = '?' + selectBox.attr('name') + '=' + currentSelection;
            } else {
                var refUrl = '?' + selectBox.attr('name') + '=' + encodeURIComponent(selectBox.val());
            }
            
            $('section.overview.locations').addClass('load').load(refUrl + ' section.overview.locations', function (response, status) {
                success: {
                    slimmageTools.reload();
                    $('section.overview.locations > section.overview.locations').unwrap();
                }
            });
        }
    }
    
    window.search = {
        init: function () {
            var headerSearchform = $('header div.search div.searchForm');
    
            headerSearchform.addClass('js');
    
            $('header div.search div.searchForm.open div.closeBox').on('click', function () {
                $('header div.search div.searchForm').removeClass('open');
            });
    
            headerSearchform.on('click', function (e) {
                if ($(e.target).hasClass('closeBox')) {
                    headerSearchform.removeClass('open');
                } else {
                    if (!headerSearchform.hasClass('open')) {
                        headerSearchform.addClass('open');
                        headerSearchform.find('input').focus();
                    }
                }
            });
        }
    }
    
    window.searchPage = {
        init: function () {
            $('div.searchFilters > span.openSub').on('click', function () {
                $('div.searchFilters')[($('div.searchFilters').hasClass('showSub')) ? 'removeClass' : 'addClass']('showSub');
            });
    
            $('div.searchFilters input[type=checkbox]').on('change', function () {
                $(this).parents('form').submit();
            });
        }
    }
    
    window.borderTables = {
        init: function () {
            if ($('table').attr('border') == "1") {
                $('table').each(function () {
                    $(this).addClass('bordered');
                });
            }
        }
    }

    menu.init();
    pagetools.init();
    backToTop.init();
    topTasks.init();
    showmore.init();
	search.init();
	ambassadors.init();
	expertises.init();
    
    selectBox.init($('div.form select'));

    if ($('section.locations.overview').length > 0)
        locationFilter.init();

    if ($('div.serviceWidget').length > 0)
        serviceWidget.init();

    if($('div.wizardHolder').length > 0)
        wizard.init();

    if ($('.carousel').length > 0)
        carousel.init();

    if ($('.socialLine').length > 0)
        socialLine.init();

    if ($('.searchFilters').length > 0)
        searchPage.init();

    if ($('table').length > 0)
        borderTables.init();


    window.oncontextmenu = function(e)
    {
        if (e.target.nodeName == 'DIV')
        {
            return false;
        }
    }
});