// Import JS files from the old frontend setup, this will help compile and make it run through a single file
import './classic/cookie';
import './classic/dcshare';
import './classic/owl.carousel';
import './classic/main';
import './classic/media';

import './libraries/slimmageSettings';
import './libraries/slimmage';

// App
import App from './app';
import PolyfillController from './controllers/polyfill';

(async () => {
    // document.querySelector('html').classList.remove('no-js');

    const polyfills = new PolyfillController();
    await polyfills.init();

    try {
        const app = new App();
        app.resizeThrottle = 10;
        app.scrollThrottle = 100;
        window.app = app;
    
        app.polyfillController = polyfills;

        // Modules
        const loadModules = () => {
            // Example of how to load a module
            // if (document.querySelector('.shareContainer')) {
            //     import( /* webpackChunkName: "modules/share" */ './controllers/share/controller').then(e => {
            //         app.shareController = new e.default();
            //     });
            // }
        };

        loadModules();

        await app.init();
    } catch(e) {
        console.error(`Something went wrong initializing the app`);
        throw e;
    };

    console.log(`App initialized`);
    // document.documentElement.classList.remove('preventAnimations');

})();